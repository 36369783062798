@import "../variables";

.base-field {
  .bp4-input {
    @include inputBase;
  }

  .bp4-input-action {
    padding-top: 5px;
    padding-right: 10px;

    .bp4-icon {
      color: #7f90a2;
    }
  }

  .bp4-input:disabled,
  .bp4-input.bp4-disabled,
  .bp4-input-group.bp4-disabled .bp4-input:disabled {
    background-color: $white;
    color: $mainBlackText;
  }

  .bp4-input::placeholder {
    color: $mainGrayText4;
  }
}

.bp4-input.bp4-tag-input.bp4-multi-select {
  @include inputBase;
  cursor: pointer;
  align-items: center;

  &.bp4-disabled {
    cursor: not-allowed;

    .bp4-tag {
      margin-left: 5px;
    }
  }

  .bp4-input-ghost.bp4-multi-select-tag-input-input {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .bp4-button.bp4-minimal {
    padding: 0px 0px 0px 0px;
    margin: 3px 10px 0px 0px;

    .bp4-icon.bp4-icon-cross {
      padding-top: 0px;
      padding-left: 0px;
      color: $red1;
      background-color: $blue3;
    }
  }
}

.has-required-background {
  .bp4-input {
    background-color: #FFEFD9;
    box-shadow: none;
    border-radius: $standardBorderRadius;
    border: 1px solid #FFCC00;
  }

  .bp4-control.bp4-radio.bp4-inline {
    background-color: #FFEFD9;
    box-shadow: none;
    border-radius: $standardBorderRadius;
    border: 1px solid #FFCC00;
  }

  .bp4-input.bp4-tag-input.bp4-multi-select {
    background-color: #FFEFD9;
    box-shadow: none;
    border-radius: $standardBorderRadius;
    border: 1px solid #FFCC00;
  }

  .bp4-input-action {
    padding-top: 5px;
    padding-right: 10px;

    .bp4-icon {
      color: #7f90a2;
    }
  }

  .bp4-input-group.select-input {
    cursor: pointer;

    .bp4-input {
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.radio-field {
  .bp4-control {
    input:checked~.bp4-control-indicator {
      background-color: $mainGray6;

      &::before {
        background-image: radial-gradient(#ffffff,
            #ffffff 36%,
            transparent 40%);
      }
    }
  }

  .bp4-disabled {
    input:checked~.bp4-control-indicator {
      opacity: 0.5;
    }
  }
}

.checkbox-field {
  input:checked~.bp4-control-indicator {
    background-color: $mainGray6;
  }

  input:checked~.bp4-control-indicator:hover,
  .bp4-control:hover input:checked~.bp4-control-indicator {
    background-color: lighten($mainGray6, 10%);
  }

  input:disabled:checked~.bp4-control-indicator {
    background-color: $mainGray6;
    opacity: 0.5;
  }
}

.select-field {
  .bp4-tag {
    background-color: $blue3;
    color: $mainBlueText2;
  }

  .bp4-input.bp4-disabled {
    background-color: transparent;
    padding-left: 0;
  }

  .bp4-input-action {
    padding-top: 5px;
    padding-right: 10px;
  }

  .bp4-input-group.select-input {
    cursor: pointer;

    .bp4-input {
      @include inputBase;
      cursor: pointer;
      box-shadow: none;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.select__menu-item.bp4-active.bp4-intent-primary {
  background-color: $blue3;
  color: $mainBlackText;
}

.numeric-input-field {
  .bp4-numeric-input {
    max-width: 250px;
    margin-top: 8px;

    .bp4-input-group {
      width: 100%;

      .bp4-input {
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .bp4-button-group.bp4-vertical {
      .bp4-button {
        margin-left: 1px;
      }

      .bp4-button.bp4-disabled {
        display: none;
      }
    }
  }
}

.bp4-tag-remove .bp4-icon:first-child {
  color: gray;
}
.application-actions {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
}

.application-page-main-content {
  padding-top: 0;
  width: calc(100% - 60px);
  min-width: calc(100% - 650px);
  margin: 1rem 1rem 1rem 1rem;
  height: 100%;
  height: calc(100% - 25px);
  margin: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
}

.application-page-header {
  padding-top: 0;
  display: grid;
  grid-template-columns: 1fr 282px;
  overflow: auto;
  margin-bottom: 5px;

  .application-page__forms.bp4-tabs {
    height: 100%;
    border: 1px solid #d7d7d7;

    background-color: #fff;
    border-radius: 2px;
    min-width: 30rem;
    margin-right: 1rem;

    .bp4-tab-list>*:not(:last-child) {
      margin-right: 40px;
    }

    .bp4-tab-list {
      padding-top: 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      border-radius: 2px 2px 0 0;
      border-bottom: 1px solid #d7d7d7;

      .bp4-tab {
        padding: .2rem;
      }

      .bp4-tab[aria-selected="true"],
      .bp4-tab:not([aria-disabled="true"]):hover {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .bp4-tab-panel {
      height: calc(100% - 53px);
      margin-top: 0;

      .application-card {
        height: 100%;
      }
    }
  }

  .application-page-card {
    border-radius: 0 0 2px 2px;
    background-color: #fff;
    height: 100%;

    .relativity-card {
      margin-bottom: 0px;

      .bp4-elevation-0 {
        box-shadow: unset;
      }
    }
  }
}

.application-form-tab {
  display: flex;
  justify-content: center;
  align-items: center;

  .bp4-tag.bp4-round {
    background-color: #ffefd9;
    color: #ffb54f;
    font-size: 12px;
    line-height: 10px;
    min-height: 10px;
    min-width: 10px;
    margin-left: 5px;
  }
}

.loading_content {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;

  .application-page-loading-spinner {
    flex-grow: 1;
  }
}

.eligibility-summary-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .eligibility-summary-data-point {
    padding-left: 25px;
    padding-right: 25px;

    .multiple-tooltip-banner {
      display: flex;

      .asset-disclaimer-icon {
        padding-top: 2px;
        padding-left: 7px;
      }
    }
  }
}
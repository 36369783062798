$white: #ffffff;
$white2: #f2f5f8;
$mainBackground: #F5F5F5;

$sidebarBlue: #4489c5;
$sidebarGray: #515e75;

$mainBlueText1: #3c85f3;
$mainBlueText2: #4d85eb;
$blue3: rgba(77, 133, 235, 0.2);

$mainGrayText1: rgba(6, 34, 65, 0.5);
$mainGrayText2: #7f90a2;
$mainGrayText3: #7b90a4;
$mainGrayText4: #afb7c4;
$mainGray5: #f8fafc;
$mainGray6: #505e70;
$mainGray7: #dadde3;
$mainGray8: #dadde36b;
$mainGray9: #444444;

$mainBlackText: #0d223f;
$iconTextHover: #badfff;

$blueprintDialogHeaderText: #1c2127;

$green1: hsl(156, 69%, 48%);
$green2: rgba(38, 205, 137, 0.2);
$green3: rgba(40, 205, 137, 0.15);
$green4: hsl(145, 70%, 75%);
$green5: hsl(145, 55%, 46%);

$orange1: #fea500;
$orange2: rgba(255, 189, 99, 0.2);
$orange3: #fea5009e;

$red1: hsl(350, 78%, 53%);
$red2: hsl(350, 78%, 88%);
$red3: #f8c9d066;

$backgroundHighlight: rgba(13, 34, 63, 0.05);

$tagGreenBackground: $green2;
$tagGreenText: $green1;

$tagGreenBackground2: #cdf4d0;
$tagGreenText2: #4dd45a;

$tagBlueBackground: #d4e3ff;
$tagBlueText: #5e90ed;

$tagGreyBackground: #fbe8eb;
$tagGreyText: #d51d3d;

$tagRedBackground: rgba(232, 65, 93, 0.2);
$tagRedText: $red1;

$tagYellowBackground: #f5f1bd;
$tagYellowText: #d5c31d;


$warningCalloutBackground: #feefd9;
$warningIcon: #ffa11e;

$tooltipBackground: rgba(14, 22, 36, 0.85);
$tooltipMainText: $white;
$tooltipHeaderText: $mainGrayText4;

// Spacings
$normalSpacing: 16px;
$mediumSpacing: 20px;
$extraLargeSpacing: $normalSpacing * 2;
$largeSpacing: 32px;
$standardBorderRadius: 6px;

$standardFontSize: 16px;

$iterationEngineTabsWidth: 75%;
$iterationEngineCommentWidth: calc(100% - #{($iterationEngineTabsWidth)});

$iterationEngineTabListWidth: 26%;
$iterationEngineFormContainerWidth: calc(100% - #{$iterationEngineTabListWidth});

// Border and Shadows
$cardBoxShadow: 0px 0px 4px 2px #e5e5e5ab;
$cardBorderRadius: 4px;
$pillBoxShadow: 0px 0px 1px 1px #e1dfdfab;
$pillBoxShadowHover: 0px 0px 1px 1px $orange3;
$relativitySubCardBottomBorder: 1px solid #11141826;

// Grid
$gridBorderColor: #e0e0e0;
$midBorderColor: #e0e0e07a;
$inputBorderColor: #7a8cac;

// Schedule
$scheduleBorder: #dadada 1px solid;

// Input
@mixin inputBase {
  background-color: $mainGray5;
  border: solid 1px #E6ECF0;
  box-shadow: none;
  border-radius: $standardBorderRadius;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Pill 
@mixin pill {
  background-color: $mainGray8;
  border: none;
  color: $mainBlackText;
  padding: 2px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
  width: 100%;
}

// Document Thumbnail
$docThumbnailWidth: 238px;
$docThumbnailHeigth: 343px;
$docThumbnailListItemWidth: $docThumbnailWidth + 4;
$docThumbnailListItemHeight: $docThumbnailHeigth + 4;
$docThumbnailRotateMarginOffset: 52px -52px;

@mixin docThumbnailDefaultGrid {
  grid-template-columns: repeat(8, 1fr);
  gap: 40px;
}

@mixin docThumbnailMediaGrid {
  $docThumbnailGap: $docThumbnailHeigth - 10;
  $docThumbnailMediaCount: 3400px;
  $docThumbnailGridCols: 8;

  @while ($docThumbnailGridCols >=1) {
    @media(max-width: $docThumbnailMediaCount) {

      .document-pages-viewer-item-container .document-pages-viewer-item .document-pages-viewer-item-pages .list-box-wrapper .list-box {
        grid-template-columns: repeat($docThumbnailGridCols, 1fr);
      }
    }

    $docThumbnailMediaCount: $docThumbnailMediaCount - $docThumbnailGap;
    $docThumbnailGridCols: $docThumbnailGridCols - 1;
  }
}


// Dialog 
$dialogFullscreenHeigth: 95vh;
$dialogFullscreenHeaderHeigth: $dialogFullscreenHeigth * 0.0800;
$dialogFullscreenContentHeigth: $dialogFullscreenHeigth * 0.8400;
$dialogFullscreenFooterHeigth: $dialogFullscreenHeigth * 0.0800;

// Document Splitter
// Keeping numbers for reference. This computations help to handle full height of the dialog and
// their inner containers. Next to the variables below are the raw numbers and percentages that
// were used to calculate the variables. It is important to keep the numbers in sync to have them
// adjust the container heights accordingly. This functionality helps to keep the dialog responsive
// and to scroll the content inside the dialog without having to scroll the whole dialog. It helps to
// keep all inner containers the right size to fit the content within the dialog showing the correct scrollbars.
// It also helps to showcase the preview panel atop the content without affecting the scroll position of the content panel.
// Calculation
//800 - 695 = 105
//105 / 800 = 0.1325
//0.1325 * 100 = 13.25
//100 - 13.25 = 86.75
//86.75 / 100 = 0.8675
$docSplitterHeigth: $dialogFullscreenHeigth; //800px;
$docSplitterContentHeigth: $docSplitterHeigth * 0.8675; //695px;
$docSplitterFooterHeigth: $docSplitterHeigth * 0.05;
$docSplitterDocumentViewerDocumentsHeigth: $docSplitterHeigth * 0.8563; //685px;
$docSplitterDocumentPreviewObject: $docSplitterHeigth * 0.8; //640px;

// Footer
$footer-height: min(70px, 70px);
@import '../../variables';

.page.template-details .content .page-main-content {
  padding: 0;
}

.template-details {

  .template-details_header-text.bp4-skeleton {
    min-width: 100px;
    min-height: 30px;
  }

  button.bp4-button.bp4-button.bp4-minimal {
    &.template-details__edit-button {
      background: none;
      padding: 6px 14px 9px 10px;
      margin: 5px;

      &:hover {
        background: $mainGray5;
      }
    }
  }
}
@import "../variables";

.page {
  display: flex;
  height: 100%;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100% - 64px);
    padding: 1rem 1rem 1rem 85px;
    overflow: auto;
    width: 100%;

    .page-main-content {
      width: 100%;

      section {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
      }
    }
  }

  .bp4-non-ideal-state:has(.error-page-signout) {
    justify-content: center;
  }

  .error-page-signout {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.application-page.page {

  .application-page__forms__primary.bp4-tabs {
    height: calc(100% - -2px);
    border-left: 1px solid #fff;

    .bp4-tab-list {
      background: #FFF;
      overflow-y: hidden;
      padding: 0 15px;
      border: 1px solid #ddd;
      margin-left: -1px;

      .bp4-icon.bp4-icon-maximize,
      .bp4-icon.bp4-icon-minimize {
        margin-bottom: 5px;
        color: $sidebarBlue;
        cursor: pointer;
      }
    }

    .bp4-tab-panel {
      margin-top: 0;
      height: calc(100% - 35px);

      .application-card {
        height: 100%;

        >.relativity-card {
          box-shadow: 0 1px 4px 0 #1522321c;
        }
      }
    }
  }
}

.application-form-page.page {
  .page-main-content {
    display: flex;
    flex-direction: row;
  }
}

.page {
  .page-main-content {

    section {
      &.board-toolbar {
        margin-bottom: 15px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;

          li {
            padding: 5px;
            font-size: 14px;
          }
        }

        button.bp4-button.bp4-button {
          font-size: 14px;
          margin: 0;
          padding: 10px;
        }
      }

      &.board-grid-container,
      .grid-container {
        display: block;
        overflow: hidden;

        .application-grid,
        .template-grid {
          .e-grid.grid {

            .e-content {
              max-height: unset;
            }
          }
        }
      }
    }
  }
}

.bp4-dialog-body {
  section {
    &.board-toolbar {
      margin-bottom: 15px;

      ul.board-toolbar-right {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 50%;
      }

      button.bp4-button.bp4-button {
        font-size: 14px;
        margin: 0;
        padding: 10px;
        margin-left: auto;
        margin-right: 0px;
      }
    }

    &.board-grid-container,
    .grid-container {
      display: block;
      overflow: hidden;

      .billing-rule-grid {
        .e-grid.grid {

          .e-content {
            height: calc(100% - 18.7rem);
          }
        }
      }
    }
  }
}

.help-center-topic-search-result {
  border-bottom: 1px solid #ddd;
  margin-left: 15px;
  padding-bottom: 10px;
  padding-top: 5px;
  margin-bottom: 0;
  width: 98%;

  .help-center-topic-search-title {
    font-size: 14px;
    font-weight: bold;
  }

  .help-center-topic-search-preview {
    line-height: 1.1;
    white-space: normal;
    width: 100%;
    font-size: 12px;
    color: #777;
  }
}

$helpcenterTopicsHeight: 100%;
$helpcenterSupportHeight: 1500px;

.helpcenter-page {
  .content {
    .page-main-content {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: start;

      .ucl-card {
        border: 1px solid #ddd;

        iframe {
          border: 0;
          width: 100%;
          height: $helpcenterSupportHeight;
          background: transparent;
        }
      }

      .helpcenter-container {
        padding: 15px;
        width: 100%;

        .bp4-non-ideal-state {
          margin-top: 100px;
          padding-top: 20px;
        }

        .helpcenter-header {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;

          &__title {
            margin: 0;
            font-size: 32px;
            font-weight: 500;
            color: $sidebarGray;
            margin-bottom: 15px;
          }

          &__description {
            font-size: 14px;

            p {
              margin: 15px 0 0 0;
            }
          }
        }

        .helpcenter-search {
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 30px;

          .helpcenter-search__input {
            background: transparent;

            &.e-input-group {
              &:not(.e-success) {
                background: transparent;
                border: none;
                box-shadow: none;
                outline: none;
                border-radius: 4px;
                min-height: 30px;
                height: 30px;
                max-height: 30px;
                box-sizing: border-box;
              }
            }


            &:after {
              content: "\e97d";
              font-family: 'e-icons';
              position: absolute;
              top: 7px;
              left: 10px;
              color: #bbb;
              font-weight: bold;
              cursor: default;
            }


            input {
              border: 1px solid #ddd;
              box-shadow: none;
              outline: none;
              border-radius: 4px;
              min-height: 30px;
              height: 30px;
              max-height: 30px;
              box-sizing: border-box;
              padding: 0 80px 0 30px;

              &:hover {
                border: 1px solid #ddd !important;
                box-shadow: none !important;
              }
            }
          }

          .helpcenter-search__button {
            position: absolute;
            top: 2px;
            right: 3px;
            margin: 0;
            height: 26px;
            min-height: 26px;
            padding: 0 15px;
            border-radius: 12px 4px 4px 12px;
            font-size: 14px;
            border: 1px solid hsla(0, 0%, 87.8%, .478431);
            outline: none;
          }
        }

        .helpcenter-sections {
          height: $helpcenterTopicsHeight;
          width: 100%;

          .control_wrapper {
            width: 100%;
          }

          &:has(iframe) {
            height: $helpcenterSupportHeight + 100px;
          }

          .e-toolbar .e-toolbar-items.e-toolbar-multirow {
            gap: 30px;
          }

          .e-tab .e-tab-header .e-toolbar-item {
            height: 50px;
            margin-bottom: 0;
            ;

            .e-tab-wrap {
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              height: auto;
            }

            .e-text-wrap {
              height: auto;
            }

            .e-tab-text {
              padding: 5px 25px 5px 3px;
              border-radius: 4px;
              font-weight: 500;
            }

            &.e-active {
              border: none;
              margin-bottom: 0;
            }
          }

          .e-content {
            .help-center-topics-toolbar {
              display: flex;
              justify-content: end;
              align-items: center;
              margin-bottom: 15px;
              position: absolute;
              top: -40px;
              right: 0;
              z-index: 1;

              .help-center-topics-toolbar--actions-create {
                margin: 0;
                margin-right: 3px;
                font-size: 14px;
                border: 1px solid #ddd;
                padding: 2px 10px 3px 5px;
                min-width: 100px;
              }
            }

            .help-center-topics-no-data {
              .help-center-topics-toolbar {
                width: 100%;
                justify-content: center;
              }
            }

            .help-center-topics-items {
              overflow-y: auto;
              height: $helpcenterTopicsHeight;
            }

            .e-accordion {
              background: transparent;
              border: none;

              .e-acrdn-item .e-acrdn-header {
                height: 80px;
                max-height: 80px;
                min-height: 80px;
                overflow: hidden;
                width: 100%;

                .e-acrdn-header-content {
                  width: 100%;
                }
              }

              .e-acrdn-item.e-select {
                margin-bottom: 15px;
                background: $white;
                border: 1px solid #ddd;
                box-shadow: $cardBoxShadow;
                border-radius: $cardBorderRadius;
                width: 100%;

                .help-center-topic-header {
                  position: relative;
                  width: 100%;

                  .help-center-topic-header__title {
                    font-size: 14px;
                    font-weight: bold;
                  }

                  .help-center-topic-header__content {
                    line-height: 1.2858142857;
                    white-space: normal;
                    width: 100%;

                    &.help-center-topic-header__content--preview {
                      font-size: 12px;
                      color: #777;
                      opacity: 1;
                      position: absolute;
                      top: 40px;
                      transition: all 600ms ease-in-out;
                    }

                    &.help-center-topic-header__content--actions {
                      animation: 300ms ease-in-out 0s 1 enterA;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      position: absolute;
                      top: 100px;

                      .help-center-topic-header__content--actions-metadata {
                        font-size: 11px;
                        color: #777;
                      }

                      .help-center-topic-header__content--actions-edit {
                        font-size: 12px;
                        background: none;
                        margin: 0;
                        padding: 0 10px 0 5px;
                        margin-right: -30px;
                        border: 1px solid transparent;

                        &:hover {
                          background: #eee;
                          border-color: #ccc;
                        }

                        .bp4-icon {
                          padding-top: 1px;
                        }

                        .bp4-icon svg:not([fill]) {
                          width: 12px;
                          height: 12px;
                        }
                      }
                    }
                  }
                }

                &.e-expand-state {
                  .help-center-topic-header .help-center-topic-header__content.help-center-topic-header__content--preview {
                    opacity: 0;
                    top: 200px;
                  }

                  .help-center-topic-header .help-center-topic-header__content.help-center-topic-header__content--actions {
                    position: relative;
                    top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes enterA {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-topics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 1px;
  overflow: hidden;

  .card-topic-card {
    .ucl-card__body {
      display: flex;
      align-items: center;
      justify-content: center;

      .card-topic-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;

        .card-topic-content__actions {
          position: absolute;
          top: -40px;
          right: 10px;

          .card-topic-content__actions-edit {
            font-size: 12px;
            background: none;
            margin: 0;
            padding: 0 10px 0 5px;
            margin-right: -30px;
            border: 1px solid transparent;

            &:hover {
              background: #eee;
              border-color: #ccc;
            }

            .bp4-icon {
              padding-top: 1px;
            }

            .bp4-icon svg:not([fill]) {
              width: 12px;
              height: 12px;
            }
          }
        }

        .card-topic-content__title {
          margin-bottom: 15px;
          font-weight: 500;
        }

        .card-topic-content__link {
          button.bp4-button.bp4-button.bp4-minimal {
            border-radius: 30px;
            padding: 7px 18px;
            border: 1px solid #ddd;
            font-size: 14px;

            .bp4-icon {
              margin-top: -2px;
              margin-right: 10px;
            }
          }
        }
      }


    }
  }

  .card-topic-card.searched-topic {
    background-color: #D1EBFF;

    .bp4-button.bp4-button.bp4-minimal {
      background-color: #FFF
    }
  }
}

.fia-page {
  .fia-grid {
    height: calc(100vh - 70px);

    .ucl-grid-views-picker {
      margin-top: 10px;
    }

    .grid-filters-module {
      top: 80px;
    }

    .grid-metrics {
      .ucl-card__body {
        min-height: unset;
      }
    }
  }
}

.scheduler-page {
  .header-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-bottom: 10px;

    h1 {
      margin: 0 auto 0 0;
      font-size: 32px;
      font-weight: 500;
      color: $sidebarGray;
    }
  }



  .content {
    .page-main-content {
      .control_wrapper {
        height: 95%;

        .in-app-scheduler {
          height: 95%;
          overflow-y: auto;
          display: flex;

          .e-checkbox-wrapper .e-frame,
          .e-css.e-checkbox-wrapper .e-frame {
            border: 1px solid;
          }

          .e-schedule .e-vertical-view .e-work-cells:hover {
            background: #FFF;
          }

          .e-schedule .e-vertical-view .e-work-cells {
            background: #FFF;
          }

          .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
            cursor: pointer;
            opacity: 0.9;

            &:hover {
              opacity: 1;
            }

            &[aria-disabled='true'] {
              cursor: default;
              box-shadow: none;
              border: 1px solid #a3d7ff;

              &:hover {
                opacity: 0.9;
              }
            }

          }

          .e-toolbar-item.e-date-range {
            .e-tbar-btn {
              background: #FFF;
              cursor: default !important;

              &:hover {
                background: #FFF;
                cursor: default !important;
              }

              .e-tbar-btn-text {
                cursor: default !important;
              }

              .e-btn-icon {
                display: none;
              }
            }
          }

          &.in-app-scheduler-facility {
            height: 100%;

            .e-timeline-view .e-content-wrap table tr:last-child td,
            .e-timeline-month-view .e-content-wrap table tr:last-child td {
              border-bottom-width: 1px;
            }

            .facility-scheduler-teams-resources {
              padding: 0 !important;
              width: 100%;
            }

            .resource-checkbox-container {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }

            .e-control.e-schedule {
              &.e-event-action {
                .e-schedule-table {
                  td.e-all-day-cells:has(.e-all-day-appointment-section) {
                    height: 2em !important;

                    // Won't Support For Now
                    display: none;

                    &:after {
                      content: "Full Day";
                    }
                  }

                  tr.e-all-day-row {
                    td.e-all-day-cells {
                      // Won't Support For Now
                      display: none;

                      height: 2em !important;
                    }
                  }
                }
              }

              .e-timeline-view .e-header-cells {
                .e-navigate {
                  display: flex;
                  justify-content: center;
                  font-weight: 500;

                  &:hover {
                    text-decoration: none;
                    cursor: default;
                  }
                }
              }

              // Won't Support For Now
              // &.e-event-action .e-timeline-view .e-header-cells {
              //   &:hover {
              //     background: #f3d6a0;
              //     border: 1px dashed #dba746;

              //     &:after {
              //       content: "Full Day";
              //       display: flex;
              //       justify-content: center;
              //       font-weight: 500;
              //     }

              //     .e-navigate {
              //       display: none
              //     }
              //   }
              // }

              // DEV TODO
              // .e-timeline-view .e-date-header-wrap table tbody td.e-time-slots:nth-child(odd) {
              //   &:after {
              //     content: "AM";
              //     display: flex;
              //     justify-content: center;
              //   }

              //   span {
              //     display: none;
              //   }
              // }

              // DEV TODO
              // .e-timeline-view .e-date-header-wrap table tbody td.e-time-slots:nth-child(even) {
              //   &:after {
              //     content: "PM";
              //     display: flex;
              //     justify-content: center;
              //   }

              //   span {
              //     display: none;
              //   }
              // }

              // Dev TODO
              // .e-time-cells-wrap {
              //   tr {
              //     td.e-time-cells.e-time-slots {
              //       &:after {
              //         content: "Afternoon";
              //       }

              //       span {
              //         display: none;
              //       }
              //     }

              //     &:first-child {
              //       td.e-time-cells.e-time-slots {
              //         &:after {
              //           content: "Morning";
              //         }
              //       }
              //     }
              //   }
              // }
            }
          }

          .resources-container {
            background: #fff;
            border: $scheduleBorder;
            border-right: 0;
            color: #767676;
            width: 20%;
            overflow-y: auto;

            .resources-title {
              padding: 10px;
              font-size: 18px;
              font-weight: 500;
              color: #515e75;
              border-bottom: #dadada 1px solid;
            }

            .daily-debriefs-container {
              display: flex;
              align-items: center;


              .relativity-primary-button {
                display: flex;
                flex: 1;

                .daily-debriefs-button {
                  flex: 1;

                  color: #0078d6;
                  background: none;
                  border: 1px solid #0078d6;
                }

                .daily-debriefs-button:hover {
                  background: #e7f4ff;
                }
              }

              .daily-debriefs-badge {
                margin-right: 5px;
                min-height: 24px;
                min-width: 10px;
                max-width: 44px;

                font-size: 12px;
                line-height: 10px;
                color: #ffb54f;
                background-color: #ffefd9;
              }
            }

            .resources-items-header {
              padding-left: 5%;
              display: flex;
              align-items: center;
              width: 90%;
              justify-content: space-between;
              margin: 15px 0 10px 0;

              h3 {
                font-weight: 500;
                margin: 0;
              }

              .bp4-icon:hover {
                cursor: pointer;
              }
            }

            .resource-items {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 3% 3% 3% 7%;
              background-color: #f8f9fa;

              .resource-checkbox-style-select-all {
                margin-bottom: 10px;
              }

              .resource-item-filtered {
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 10px;
                color: #515e75;
                font-weight: 450;
                font-size: 16px;
                margin-bottom: 10px;

                .bp4-icon:hover {
                  cursor: pointer;
                }
              }

              .resource-checkbox-tm-facility {
                margin-left: 30px;
              }

              .resource-items-filter {
                width: 100%;

                .ucl-label-title {
                  font-size: 14px;
                  font-weight: normal;
                  margin-bottom: 5px;
                }


                .e-input-group-icon.e-search-icon {
                  background: #f8f9fa;
                }
              }

              .resource-items-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h3 {
                  font-size: 14px;
                  font-weight: normal;
                  margin: 10px 0 5px 0;
                }

              }

              .e-checkbox-wrapper.e-wrapper {
                padding: 5px;
              }

              button {
                margin: 0;
                padding: 0;
                background: none;
                border: 1px solid transparent;
                border-radius: 50%;
                color: #588ac6;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                opacity: 0.7;
                width: 30px;
                min-width: 30px;

                &:hover {
                  opacity: 1;
                  color: #fff;
                  background: #588ac6;
                }
              }
            }

          }
        }
      }

      .e-tab .e-tab-header .e-toolbar-item {
        height: 50px;
        margin-bottom: 0;

        .e-tab-wrap {
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          height: auto;
        }

        .e-text-wrap {
          height: auto;
        }

        .e-tab-text {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 25px;
          border-radius: 4px 4px 0 0;
          font-weight: 500;

          &:hover {
            background: #ebebeb;
          }
        }

        &.e-active {
          border: none;
          margin-bottom: 0;
          font-weight: 500;
        }
      }
    }
  }
}

.reports-page {
  .bp4-non-ideal-state.bp4-non-ideal-state-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    border: 1px dashed #ccc;
    border-radius: 40px;
  }

  .reports-page__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    width: 100%;

    .reports-page-header__title {
      margin: 0;
      font-size: 32px;
      font-weight: 500;
      color: #515e75;
    }

    .reports-page__header--actions-create {
      margin: 0;
      margin-right: 3px;
      font-size: 14px;
      border: 1px solid #ddd;
      padding: 2px 10px 3px 5px;
      min-width: 100px;
      height: 34px;
    }
  }

  &__reports {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin: 15px;
    max-height: 100%;
    display: grid;
    column-gap: 15px;
    grid-template-columns: repeat(4, 1fr);


    &__report-item {
      position: relative;
      width: 100%;
      height: 300px;
      border-radius: 0.5em;
      background-size: cover;
      margin-bottom: 13px;

      &__overlay-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
      }

      &__title.bp4-heading {
        color: white;
      }
    }
  }
}

button.bp4-button.bp4-button.bp4-minimal.reports-page__reports__report-item--actions-edit {
  animation: 300ms ease-in-out 0s 1 enterA;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: 12px;
  background: none;
  margin: 0;
  padding: 0 10px 0 5px;
  border: 1px solid transparent;
  color: #FFF;

  &:hover {
    background: #eee;
    border-color: #ccc;
  }

  .bp4-icon {
    padding-top: 1px;
  }

  .bp4-icon svg:not([fill]) {
    width: 12px;
    height: 12px;
  }
}

.board-toolbar-right {
  li {
    padding: 2px;
  }
}

.AI-flex-container {
  height: 100%;
}

.schedule-tabs {
  .bp4-tab-panel {
    height: 85vh;
    overflow-y: hidden;
  }
}

[id^="filterTooltip"][id$="content"].e-tooltip-wrap.e-popup.e-lib.e-control.e-popup-open {
  height: 250px !important;
  width: 250px !important;
}
@import "../variables";


.bp4-tag.bp4-intent-danger.bp4-minimal {
  background-color: $tagRedBackground;
  color: $tagRedText;
}

.bp4-tag.bp4-intent-success.bp4-minimal {
  background-color: $tagGreenBackground;
  color: $tagGreenText;
}

.bp4-tag.bp4-minimal.bp4-round {
  width: 94px;

  .bp4-fill {
    font-size: 14px;
    line-height: 24px;
    margin: 2px 0;
    text-align: center;
  }
}

.bp4-portal {

  &:has(.bp4-popover2-appear-done),
  &:has(.bp4-popover2-enter-done) {
    z-index: 4000 !important;
  }

  .bp4-popover2 {
    box-shadow: $pillBoxShadow;
    border-radius: 10px;
  }
}

.bp4-portal.tooltip-popover {
  .bp4-popover2-arrow {
    path {
      fill: $tooltipBackground;
    }
  }

  .bp4-popover2-content {
    padding: 10px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    background-color: $tooltipBackground;
    color: $tooltipMainText;
    max-height: fit-content;
  }
}

.bp4-overlay,
.bp4-overlay-content,
.bp4-overlay-backdrop {
  // needs to go over pdf selections / toolbar, and so on (ex. 1100)
  z-index: 1200;
}

.bp4-toast-container {
  z-index: 9999;
}

.bp4-toast.bp4-intent-primary {
  background-color: $tagBlueBackground;
}

.bp4-toast {
  z-index: 99999;

  .bp4-toast-message {
    font-size: 16px;
    color: $mainBlackText;

    h3 {
      margin-top: 0;
    }
  }

  .bp4-button-group .bp4-button .bp4-icon {
    color: $mainBlackText !important;
  }

  .bp4-button-group {
    align-self: flex-start;

    button.bp4-button {
      margin-right: 0;
    }
  }

  &.bp4-intent-danger {
    background-color: $red2;
  }

  &.bp4-intent-success {
    background-color: $green4;
  }
}

.bp4-datepicker {
  box-shadow: none !important;
}

// Select Popover
.bp4-select-popover .bp4-popover-content,
.bp4-select-popover .bp4-popover2-content {
  overflow: hidden;
}

.bp4-select-popover .bp4-menu:not(:first-child) {
  padding-bottom: 20px;
}

// Dialog
.bp4-dialog {
  .bp4-dialog-header {
    padding: 0 5px 0px 10px;

    .bp4-heading {
      font-size: 16px;
      font-weight: bold;
    }

    button.bp4-button.bp4-button.bp4-minimal {
      background: none;
      margin-right: 0;
      @include bp4-button-minimal;
    }
  }
}
@import "../variables";

$hoverChangeAmount: 5%;
$activeChangeAmount: 10%;

button.bp4-button.bp4-button {
  font-size: 16px;
  padding: 6px 10px 9px 10px;
  margin: 0 2px;
  border-radius: 6px;

  &.bp4-intent-success {
    background-color: $green4;
    color: $mainBlackText;
    box-shadow: none;

    &:hover {
      background-color: darken($green4, $hoverChangeAmount);
      color: darken($mainBlackText, $hoverChangeAmount);
    }

    &:active {
      background-color: darken($green4, $activeChangeAmount);
      color: darken($mainBlackText, $activeChangeAmount);
    }
  }

  &.bp4-intent-danger {
    background-color: $red2;
    color: $mainBlackText;
    box-shadow: none;

    &:hover {
      background-color: darken($red2, $hoverChangeAmount);
      color: darken($mainBlackText, $hoverChangeAmount);
    }

    &:active {
      background-color: darken($red2, $activeChangeAmount);
      color: darken($mainBlackText, $activeChangeAmount);
    }
  }
}

// Base Minimal Button
@mixin bp4-button-minimal {
  color: $mainBlueText2;
  background: $white2;
  font-size: 16px;
  padding: 6px 10px 9px 10px;
  margin: 0 2px;
  border-radius: 6px;
  border: 1px solid #dce0e5;

  .bp4-icon {
    padding-top: 3px;
  }

  &:hover {
    background: darken($white2, $hoverChangeAmount);
    color: darken($mainBlueText2, $hoverChangeAmount);
  }

  &:active {
    background: darken($white2, $activeChangeAmount);
    color: darken($mainBlueText2, $activeChangeAmount);
  }
}

button.bp4-button.bp4-button.bp4-minimal {
  background: #e7e9eb;
  @include bp4-button-minimal;
}

// Intent: Success
button.bp4-button.bp4-minimal.bp4-intent-success {
  color: $green1;

  &:hover {
    color: darken($green1, $hoverChangeAmount);
  }

  &:active {
    color: darken($green1, $activeChangeAmount);
  }
}

// Intent: Warning
button.bp4-button.bp4-minimal.bp4-intent-warning {
  color: $orange1;

  &:hover {
    color: darken($orange1, $hoverChangeAmount);
  }

  &:active {
    color: darken($orange1, $activeChangeAmount);
  }
}

// Intent: Danger
button.bp4-button.bp4-minimal.bp4-intent-danger {
  color: $red1;

  &:hover {
    color: darken($red1, $hoverChangeAmount);
  }

  &:active {
    color: darken($red1, $activeChangeAmount);
  }
}

// Disabled must be at end for overrides
button.bp4-button.bp4-button.bp4-minimal,
button.bp4-button.bp4-minimal.bp4-intent-success,
button.bp4-button.bp4-minimal.bp4-intent-warning,
button.bp4-button.bp4-minimal.bp4-intent-danger {

  &:disabled,
  &:disabled:hover {
    background: $white2;
    color: $mainGrayText4;
  }
}
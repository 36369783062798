@import "../variables";

// Skeleton
.e-skeleton.e-skeleton-text,
.e-skeleton.e-skeleton-square,
.e-skeleton.e-skeleton-rectangle {
  border-radius: 8px;
}

// Dialog
.e-popup.e-popup-open.e-dialog {
  border-radius: 6px;
}

.e-dialog .e-dlg-header-content,
.e-dialog .e-dlg-content {
  margin: 2px;
}

.e-dialog .e-dlg-header-content {
  border-bottom: 1px solid #ccc;
  padding: 15px 15px 6px;
}

.e-dialog .e-dlg-header-content+.e-dlg-content {
  padding: 15px 15px 6px;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  left: 10px;
  bottom: 4px;
}

// Grid
.e-grid {
  .e-filtered::before {
    color: $orange1;
    font-weight: bold;
  }

  .e-rowcell {
    cursor: default;
  }

  .e-toolbar {
    border-top: 0;
    border-bottom: 1px solid $gridBorderColor;
  }

  .e-toolbar,
  .e-toolbar .e-toolbar-items {
    background: transparent;
  }

  .e-toolbar .e-tbar-btn,
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    background: transparent;
    font-size: 13px;
    //box-shadow: none;
    color: $mainGrayText3;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt:active,
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt:focus,
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt:hover {
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    color: $mainGray6;

    .e-icons.e-btn-icon,
    .e-tbar-btn-text {
      background: transparent;
      border-color: transparent;
      border-radius: 0;
      color: $mainGray6;
    }
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt:active {
    background: $mainBackground;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator).e-search-wrapper {
    padding: 0;
  }

  .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
    height: 43px;
    border-color: transparent;
  }
}

// Tab
.e-tab .e-tab-header.e-vertical {
  max-width: 300px;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-icon {
  color: #633c96 !important;
}

.e-toolbar-item {
  width: 500px;
}

// Scheduler
.e-schedule-toolbar-container {
  position: sticky !important;
  top: 0 !important;
}

.e-schedule-table {
  height: 100%;
}

.e-progress-btn {
  margin: 0;
}

// Split Button
.e-split-btn-wrapper .e-split-btn {
  background: #f2f5f8;
  font-size: 16px;
  border-radius: 6px 0px 0px 6px;
  border: 1px solid #dce0e5;
}

.e-split-btn-wrapper .e-dropdown-btn {
  border: 1px solid #dce0e5;
  border-radius: 0px 6px 6px 0px;
  border-left: 0;
}
@import "../../variables";

.e-splitter .e-split-bar.e-split-bar-vertical {
  &.e-last-bar:not(.e-resizable-split-bar) {
    margin: 0 !important;
    background: transparent;
  }

  .e-navigate-arrow.e-arrow-up {
    display: none;
  }

  .e-navigate-arrow.e-arrow-down {
    display: none;
  }
}
@import "../../../node_modules/@ucl/library/lib/styles/core-level4-styles.css";
@import "../../../node_modules/@ucl/library/lib/styles/components/styles.css";
@import "../../../node_modules/@syncfusion/ej2-drawings/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inplace-editor/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-filemanager/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-calendars/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-layouts/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-pdfviewer/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-react-calendars/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-image-editor/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-schedule/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-icons/styles/fabric.css";
@import "./variables";
@import "./styles/blueprint.buttons";
@import "./styles/blueprint.tabs";
@import "./styles/blueprint.fields";
@import "./styles/blueprint.overrides";
@import "./styles/syncfusion.overwrites";
@import "./styles//synfusion.fields";
@import "./styles/themes";


html,
body,
#root {
  height: 100vh;
  background-color: $mainBackground;
  overflow: auto;

  .bp4-tab-list {
    .bp4-tab-indicator {
      background-color: rgba(13, 34, 63, 0.05);
    }
  }
}

.e-splitter.e-splitter-horizontal,
.e-splitter.e-splitter-vertical {
  border: 0;
}

.e-splitter.e-splitter-horizontal .e-pane.e-scrollable,
.e-splitter.e-splitter-vertical .e-pane.e-scrollable {
  overflow: hidden;
}

.e-splitter.e-splitter-horizontal .e-pane.e-scrollable,
.e-splitter.e-splitter-vertical .e-pane.e-scrollable {
  &.application-page-main-contact__header-pane {
    overflow-y: auto;
  }
}

// Remove the HTML loader
.base-loading__container {
  display: none;
}

$scrollbarColor: $mainGrayText4;
$scrollbarBackground: #ddd;

*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbarColor $scrollbarBackground;
}

*::-webkit-scrollbar-track {
  background: $scrollbarBackground;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbarColor;
  border-radius: 4.5px;
  border: 3px solid $scrollbarColor;
}

//Grid Checkbox
.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
  background-color: $green1;
  border-color: $green1;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
  border-color: $red1;
}

//tabs
.bp4-large>.bp4-tab {
  line-height: 30px;
}

.bp4-tab[aria-selected="true"],
.bp4-tab:not([aria-disabled="true"]):hover {
  color: $sidebarBlue;
  box-shadow: inset 0 -3px 0 $sidebarBlue !important;
}

.e-toolbar .e-toolbar-items:first-child>.e-toolbar-item:last-child,
.e-toolbar .e-toolbar-items:first-child>.e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 0px;
}

.DayPicker {
  .DayPicker-wrapper {
    .bp4-datepicker-navbar {
      justify-content: space-between;

      .bp4-button {
        margin: 0;
      }
    }
  }
}